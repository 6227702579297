import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { H2, H4 } from "../../shared/headings";

import AppleLogo from "./apple-logo.svg";
import ButtonElement from "../../shared/button-element";
import GoogleLogo from "./google-logo.svg";
import Grid from "../../shared/grid";
import React from "react";
import Section from "../../shared/section";
import StripeLogo from "./stripe-logo.svg";
import styled from "styled-components";

const Mission = ({ teamPhotos }) => {
    return (
        <Section id="mission">
            <Grid>
                <TeamGrid>
                    <Person image={teamPhotos.andres.childImageSharp.gatsbyImageData} alt={`Andres`}/>
                    <Person image={teamPhotos.gabriel.childImageSharp.gatsbyImageData} alt={`Gabriel`}/>
                    <Person image={teamPhotos.bastian.childImageSharp.gatsbyImageData} alt={`Bastian`}/>
                    <Person image={teamPhotos.olivia.childImageSharp.gatsbyImageData} alt={`Olivia`}/>
                    <Person image={teamPhotos.sergio.childImageSharp.gatsbyImageData} alt={`Sergio`}/>
                    <Person image={teamPhotos.michelle.childImageSharp.gatsbyImageData} alt={`Michelle`}/>
                    <Person image={teamPhotos.diego.childImageSharp.gatsbyImageData} alt={`Diego`}/>
                    <Person image={teamPhotos.marcos.childImageSharp.gatsbyImageData} alt={`Marcos`}/>
                    <Person image={teamPhotos.rafael.childImageSharp.gatsbyImageData} alt={`Rafael`}/>
                    <Person image={teamPhotos.dennis.childImageSharp.gatsbyImageData} alt={`Dennis`}/>
                    <Person image={teamPhotos.michael.childImageSharp.gatsbyImageData} alt={`Michael`}/>
                    <Person image={teamPhotos.daniela.childImageSharp.gatsbyImageData} alt={`Daniela`}/>
                    <Person image={teamPhotos.roger.childImageSharp.gatsbyImageData} alt={`Roger`}/>
                    <Person image={teamPhotos.kat.childImageSharp.gatsbyImageData} alt={`Kat`}/>
                    <Person image={teamPhotos.kai.childImageSharp.gatsbyImageData} alt={`Kai`}/>
                    <Person image={teamPhotos.samantha.childImageSharp.gatsbyImageData} alt={`Samantha`}/>
                    <Person image={teamPhotos.gonzalo.childImageSharp.gatsbyImageData} alt={`Gonzalo`}/>
                    <Person image={teamPhotos.felipe.childImageSharp.gatsbyImageData} alt={`Felipe`}/>
                    <Person image={teamPhotos.brian.childImageSharp.gatsbyImageData} alt={`Brian`}/>
                    <Person image={teamPhotos.thomas.childImageSharp.gatsbyImageData} alt={`Thomas`}/>
                    <Person image={teamPhotos.carlos.childImageSharp.gatsbyImageData} alt={`Carlos`}/>
                    <Person image={teamPhotos.matt.childImageSharp.gatsbyImageData} alt={`Matt`}/>
                    <Person image={teamPhotos.maria.childImageSharp.gatsbyImageData} alt={`Maria`}/>
                    <Person image={teamPhotos.laura.childImageSharp.gatsbyImageData} alt={`Laura`}/>
                    <Person image={teamPhotos.nick.childImageSharp.gatsbyImageData} alt={`Nick`}/>
                    <Person image={teamPhotos.meghan.childImageSharp.gatsbyImageData} alt={`Meghan`}/>
                </TeamGrid>
            </Grid>
            <Grid>
                <MissionTitle>We're on a mission to help you reclaim your data from the banks</MissionTitle>
                <SubheadContainer>
                    <Subhead>We believe providing access to better financial tools improves people’s lives, so we’re taking money matters into our own hands.</Subhead>
                </SubheadContainer>
                <TeamImage>
                    <StaticImage
                        src="./team.png"
                        layout="fullWidth"
                        quality={100}
                        alt=""
                        placeholder="blurred"
                    />
                </TeamImage>
                <Description>
                    <p>Staying on top of finances takes way too much time and energy. We built Copilot because we got tired of waiting around for someone else to do what we always knew was possible.</p>
                    <p>Building something like Copilot isn’t easy, but we’re lucky to have an all-star team leading the way. Our team members are diverse, working from different parts of the world and coming together to create a better personal finance experience for everyone. We care about getting it right, and about doing right by our users. Get in touch with us with any questions or feedback&#8212;we’d love to hear from you.</p>
                </Description>
                <Companies>
                    <CallToAction>
                        <ButtonElement 
                            outbound
                            href="https://careers.copilot.money"
                            style={{ padding: "8px 12px" }}
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            Join us
                            {/* <Openings>&nbsp;&ndash; 2 open roles</Openings> */}
                        </ButtonElement>
                    </CallToAction>
                    <SmallSubhead>Our team is from <ConditionalLineBreak>companies like&#8230;</ConditionalLineBreak></SmallSubhead>
                    <CompanyList>
                        <CompanyRow>
                            <CompanyLogo width={82} src={GoogleLogo} alt="Google"/>
                            <CompanyLogo width={76} src={StripeLogo} alt="Stripe"/>
                            <CompanyLogo width={23} src={AppleLogo} alt="Apple"/>
                        </CompanyRow>
                    </CompanyList>
                </Companies>
            </Grid>
        </Section>
    )
}

export default Mission

const TeamGrid = styled.div`
    grid-column: span 12;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0rem 1rem;
    padding: 0 0.25rem;
    ${props => props.theme.device.tablet} {
        grid-template-columns: repeat(7, 1fr);
        gap: 1.25rem 2rem;
        padding: 0;
        padding-bottom: 2.25rem;
    }
    ${props => props.theme.device.laptop} {
        grid-column: 3 / span 8;
    }
    ${props => props.theme.device.laptopLg} {
        padding: 0;
        padding-bottom: 3rem;
        gap: 1.75rem 3.5rem;
    }
`

const Person = styled(GatsbyImage)`
    border-radius: 88px;
    margin: 0 auto;
    margin-bottom: 16px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
`

// const Openings = styled.span`
//     display: block;
//     opacity: 0.7;
//     font-weight: 400;
// `
const MissionTitle = styled(H2)`
    text-align: left;
    grid-column: col-start / span 12;
    max-width: none;
    margin-bottom: 16px;
    margin-top: 24px;
    ${props => props.theme.device.tablet} {
        margin-bottom: 24px;
    }
    ${props => props.theme.device.laptop} {
        line-height: 1.22;
        grid-column: 3 / span 8;
    }
`
const TeamImage = styled.div`
    position: relative;
    grid-column: span 12;
    width: 100%;
    top: -0.5rem;
    margin-bottom: 0rem;
    ${props => props.theme.device.tablet} {
        grid-column: span 11;
        top: 1rem;
        margin-bottom: 2rem;
        margin-left: -0.5rem;
    }
    ${props => props.theme.device.laptop} {
        top: 1rem;
        grid-column: 3 / span 9;
        margin-bottom: 2rem;
        margin-left: -2rem;
    }
    ${props => props.theme.device.laptopLg} {
        top: 1rem;
        margin-bottom: 3rem;
        margin-left: -1.25rem;
    }
`
const Subhead = styled(H4)`
    text-align: left;
    max-width: none;
    font-weight: 600;
    ${props => props.theme.device.tablet} {
        margin-bottom: 24px;
    }
`
const SmallSubhead = styled(Subhead)`
    font-size: 0.9375rem;
    text-align: center;
    padding-top: 1rem;
    margin-bottom: 0.5rem;
    ${props => props.theme.device.tablet} {
        text-align: left;
    }
`
const ConditionalLineBreak = styled.span`
    display: inline-block;
    ${props => props.theme.device.tablet} {
        display: block;
    }
`

const Description = styled.div`
    position: relative;
    grid-column: span 12;
    ${props => props.theme.device.tablet} {
        grid-column: span 8;
    }
    ${props => props.theme.device.laptop} {
        grid-column: 3 / span 5;
    }
    & p {
        font-size: 1.1rem;
        margin-bottom: 1rem;
        line-height: 1.5;
        color: ${props => props.theme.color.textPrimary};
    }
`
const SubheadContainer = styled(Description)`
    ${props => props.theme.device.laptop} {
        grid-column: 3 / span 7;
    }
`
const CallToAction = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 16px;
    & a {
        background-color: ${props => props.theme.color.textPrimary};
    }
    ${props => props.theme.device.tablet} {
        justify-content: flex-start;
        & a {
            background-color: ${props => props.theme.color.textLink};
        }
    }
`
const Companies = styled.div`
    grid-column: span 12;
    ${props => props.theme.device.tablet} {
        grid-column: 10 / span 3;
    }
    ${props => props.theme.device.laptop} {
        grid-column: 10 / span 2;
    }
`
const CompanyList = styled.div`
    margin: 0 auto;
    text-align: center;
    ${props => props.theme.device.tablet} {
        margin: 0;
        text-align: left;
    }
`
const CompanyLogo = styled.img`
    display: inline-block;
    max-width: ${props => props.width}px;
    margin: 8px 12px;
    ${props => props.theme.device.tablet} {
        display: block;
        margin: 24px 0;
    }
    ${props => props.theme.device.laptop} {
        display: block;
        margin: 32px 0;
    }
`

const CompanyRow = styled.div`
    display: block;
    margin: 0 auto;
`